import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import NotFound from './pages/404';
import About from './pages/about';
import Consulting from './pages/consulting';
import ProjectDetails from './pages/projectDetails';


function App() {
  return (
    <>
      <Helmet>
        <title>Megazor Labs - Le Product studio des startups qui vont vite</title>    
        <meta
          name="description"
          content="On aide les startups à créer des produits SaaS dopés à l'intelligence ertificielle"
        />
      </Helmet>
      <div className="stact-container">
        <Router>
          <ScrollToTop/>
            <Routes>
              <Route path='/' element={<Consulting />} />
              <Route path='/consulting' element={<Consulting/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/project-detail/:projectId' element={<ProjectDetails/>} />
              <Route path='*' element={<NotFound/>} />
            </Routes>
        </Router>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const {pathname} = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null
}

export default App;
