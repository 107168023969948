import Helmet from "react-helmet"
import { forwardRef, useRef } from "react"
import { Link } from "react-router-dom"
import BrandSection from "../../components/brandSection"
import ContactSection from "../../components/contactSection"
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from "../../components/serviceCards"
import ProjectCards from "../../components/projectCards"
import FeatureCards from '../../components/featureCards'
import PricingCard from '../../components/pricingCard'
import './style.scss'

const Consulting = () => {
    const homeRef = useRef();
    const aboutRef = useRef();
    const pricingRef = useRef();
    const processesRef = useRef();
    const portfolioRef = useRef();

    const NavBarLinks = [
        {
            title: 'Accueil',
            ref: homeRef
        },
        {
            title:'Le labs',
            ref: aboutRef
        },
        {
            title:'Notre process',
            ref: processesRef
        },
        {
            title:'Portfolio',
            ref: portfolioRef
        },
        {
            title:'Pricing',
            ref: pricingRef
        },
    ]

    return(
        <>
            <Helmet>
                <title>Megazor Labs</title>
            </Helmet>
            <NavBar NavBarLinks={NavBarLinks}  />
            <div className="consulting">
                <Hero ref={homeRef}/>
                <Services/>
                <About ref={aboutRef} />
                <Processes ref={processesRef} />
                <ProjectsSection ref={portfolioRef} />
                {/*<Team /> */}
                <Pricing ref={pricingRef} />
                <Counters />
                <Contact/>
                <Footer NavBarLinks={NavBarLinks}/>
            </div>
        </>
    )
}

const Hero = forwardRef((props, ref) => {

    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/LogoFull-white-small.png"
        },
        {
            name: "brand2",
            imgUrl: "assets/travisnine-white-small.png"
        },
    ]

    return(
        <>
            <section ref={ref} className="section-global no-border">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <h1 style={{}}>Le Product Studio<br />100% IA</h1>
                            <p className='txt-1 mt-16 me-lg-5'>
                                On crée des apps d'intelligence artificielle en un temps record pour accélérer votre business de façon spectaculaire.</p>
                            <form className='mt-20 me-lg-5'>
                                <div className="cta-form d-flex align-items-center justify-content-between">
                                    <div className="cta-input">
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Entrez votre adresse email" />
                                    </div>
                                    <div className="cta-btn">
                                        <button type="submit" className="btn btn-primary btn-lg">S'inscrire à notre Newsletter</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <img src={require('./assets/ZOR.png')} className="img-fluid" alt="hero" width="480px" height="400px" style={{ borderRadius: "30%" }} />
                        </div>
                    </div>
                </div>
            </section>
            <BrandSection brands={brands} src={'consulting'} bordered/>
        </>
    )
})

const Services = () => {

    const services = [
        {
            name: 'SaaS',
            info: "Notre expertise unique nous permet de créer et de lancer rapidement des produits SaaS innovants et performants.",
            icoUrl: 'assets/service_ico5.svg'
        },
        {
            name: "IA",
            info: "Grâce aux technologies d'IA génératives de pointe, nous concevons des outils et produits de nouvelle génération, toujours en phase avec les tendances.",
            icoUrl: 'assets/service_ico6.svg'
        },
        {
            name: 'Vitesse supersonique',
            info: "Développement d'applications IA à la vitesse de l'éclair sans compromis sur la qualité ni la performance. Préparez-vous à être époustouflés !",
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'No code',
            info: "En seulement quelques semaines, nous élaborons des applications complètes en tirant parti de la puissance des meilleurs outils No Code tels que Bubble.",
            icoUrl: 'assets/service_ico8.svg'
        }
    ]
    return(
        <>
            <section className="section-global">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Notre raison d'être</div>
                            <h2>Créer les meilleurs applications IA en un temps record</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"  key={i}>
                                    <ServiceCards data={service} src="consulting"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const About = forwardRef((props, ref) => {

    const features_data = [
        "Développement d'outils interne",
        "Développement de SaaS",
        "Accompagnement continu"
    ]

    return(
        <>
            <section ref={ref} className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row pt-5 gy-4">
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Le labs</div>
                            <h2 className='mb-16'>Embrassez l'IA, dévorez la concurrence!</h2>
                            <p className='txt-1'>
                            Megazor Labs c'est LE product studio nouvelle génération qui aide les entreprises à accélérer leur business en développant des produits et des outils internes boostés à l'IA en un temps record.
                            Fini les délais interminables et les prix exorbitants, avec Megazor Labs, vous entrez dans une nouvelle ère.<br /><br />
                            
                            </p>
                            {features_data.map((e,i)=> <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)}
                            <br />
                            <a href="https://calendly.com/cheikhbilal/megazorlabs" target='_blank' className='btn btn-primary btn-lg' rel="noreferrer" style={{ marginTop: "12px"}}>Je réserve un call</a>
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
                            <div style={{maxWidth: '500px'}}>
                                <img src={require('./assets/rocket1.jpeg')} className="img-fluid" alt="About" style={{ borderRadius: "50%" }}/>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
})

const Processes = forwardRef((props, ref) => {

    const featurs_data = [
        {
            title: 'Appel de découverte',
            text: "Faisons connaissance et découvrez nos services."
        },
        {
            title: 'Workshop',
            text: "Une journée immersive avec nos équipes et les vôtres, où nous cernons le problème, délimitons le scope du projet et établissons les bases de notre collaboration."
        },
        {
            title: 'Build',
            text: "Grâce à des technologies Web et No code, nous développons votre outil ou produit en quelques semaines."
        },
        {
            title: "100% agile",
            text: "Nous adoptons une approche agile dans tous les aspects de notre collaboration, en favorisant la flexibilité et la communication."
        },
        {
            title: 'Iterations & support',
            text: "Nous ajustons et peaufinons votre outil en fonction de vos retours pour qu'il colle parfaitement à votre vision."
        },
    ]

    return(
        <>
            <section ref={ref} className='section-global'>
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">Notre méthodologie</div>
                            <h2>Un process simple et efficace</h2>
                        </div>
                    </div>
                </div>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={featurs_data} src='about'/>
                    </div>
                </div>
            </section>
        </>
    )
})

const ProjectsSection = forwardRef((props, ref) => {

    const projects_data = [
        {
            category: 'SaaS',
            text: "Travisnine - Boostez votre personal branding sur LinkedIn grâce à l'IA",
            imgUrl: 'assets/travisnine-ph.png',
            color: "#F97316",
            link: 'https://travisnine.com'
        },
        {
            category: 'Marketplace',
            text: "Mitmeat - La plateforme des Freelances, propulsé par l'IA",
            imgUrl: 'assets/PH.png',
            color: "#5090F0",
            link: 'https://mitmeat.com'
        },
    ]

    return(
        <>
            <div ref={ref} className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Notre portfolio</div>
                            <h2>Ils sont nés chez nous 💪</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/' className='btn btn-outline btn-arrow ms-lg-auto'>
                                Voir notre portfolio
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                    <ProjectCards data={projects_data} src="consulting" />
                    </div>
                </div>
            </div>
        </>
    )
})

const Team = () => {
    const team_data = [
        {
            name: 'Cheikh Bilal',
            position: 'Founder & CEO',
            imgUrl: 'assets/picture.png'
        },
    ]
    return(
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-lg-8">
                            <div className="section-tag mb-8">Le mot du CEO</div>
                            <h4>

Salut les entrepreneurs,<br/><br />

Oui, en 2023, il est possible de lancer des produits complètement scalables en 6 semaines et d'atteindre la rentabilité en quelques mois.<br /><br />
L'intelligence artificielle est entrain de déferler sur la planète entière et toutes les personnes qui sauront maîtriser cette technologie auront entre leurs mains des superpouvoirs.<br /><br/>

Chez Megazor Labs, on a décidé de l'exploiter pour réaliser nos rêves et construire de super boites !

</h4>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {team_data.map((e,i)=>
                            <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="team-member text-center">
                                    <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16" style={{ borderRadius: "50%", width: "200px", height: "200px"}} alt={e.name} />
                                    <div className="txt-2 color-1 fw-500">{e.name}</div>
                                    <div className="txt-3">{e.position}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Counters = () => {
    const counter_data = [
        {
            count: '2023',
            text: 'Date de création'
        },
        {
            count: '2',
            text: 'Boites lancés'
        },
        {
            count: '+400',
            text: 'Utilisateurs pour nos SaaS'
        },
        {
            count: '7+',
            text: "Années d'expériences"
        }
    ]
    return(
        <>
            <section className='counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Pricing = forwardRef((props, ref) => {

    const pricing_data = [
        /*{
            title: 'Offre Mensuelle',
            text: 'Testez notre offre sans engagement',
            price: '€6,999',
            period: 'mois',
            included: ['Développement outils & SaaS','Technologie No code', "Intégration de l'IA via Open AI", '100% agile', "Nombre de révision illimité"],
            not_included: []
        },
        {
            title: 'Offre Trimestrielle',
            text: "Economisez 1000€/mois",
            price: '€5,999',
            period: 'mois',
            included: ["Développement outils & SaaS","Technologie No Code", "Intégration de l'IA via Open AI", '100% agile', "Nombre de révisions illimité"],
            not_included: [],
            featured: true
        },*/
        {
            title: 'Offre No code',
            text: "Pour les start-ups qui veulent aller vite",
            price: '€5,999',
            period: 'mois',
            included: ["Développement outils internes", "Développement de SaaS", "Technologie No Code", "Intégration de l'IA via Open AI", '100% agile', "Nombre de révisions illimité", "Annulable à tout moment"],
            not_included: [],
        },
        {
            title: 'Offre code',
            text: "Pour les start-ups qui veulent scaler vite",
            price: '€9,999',
            period: 'mois',
            included: ["Développement outils internes", "Développement de SaaS", "Technologies Web", "Intégration de l'IA via Open AI", '100% agile', "Nombre de révisions illimité", "Annulable à tout moment"],
            not_included: [],
            featured: true
        },
        {
            title: 'Offre personnalisée',
            text: "Pour les entreprises qui ont un budget et des besoins bien précis",
            // price: '€9,999',
            // period: 'mois',
            included: ["Développement outils internes", "Développement de SaaS", "Technologies Web ou No Code", "Intégration de l'IA via Open AI", '100% agile', "Support"],
            not_included: [],
        },
    ]
    
    return(
        <>
            <section ref={ref} className='section-global'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Pricing</div>
                            <h2>Des offres pour tous les goûts</h2>
                        </div>
                    </div>
                    <div className="row">
                        {pricing_data.map((e,i)=> 
                            <div className="col-lg-4 col-md-6 gy-4" key={i}>
                                <PricingCard data={e}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
});

/*const Testimonials = () => {

    const testimonial_data = [
        {
            brandImgUrl: 'assets/review_brand1.svg',
            text: 'Love the product and the service, and the customer care team is awesome. The features are great, too--everything that you need.',
            userName: 'Mary Edwards',
            position: 'Product director',
            userImgUrl: 'assets/review_user1.svg'
        },
        {
            brandImgUrl: 'assets/review_brand2.svg',
            text: 'I recommend this product because it benefits everyone. You will be able to stay connected with your team and clients from all over the world.',
            userName: 'Felisa Rincon',
            position: 'Head of Product',
            userImgUrl: 'assets/review_user2.svg'
        },
        {
            brandImgUrl: 'assets/review_brand3.svg',
            text: 'I was able to get a fully functional online office space that included all the software we needed and it was super cheap!',
            userName: 'Eunice Kennedy',
            position: 'Product director',
            userImgUrl: 'assets/review_user3.svg'
        }
    ]

    return(
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Notre portfolio</div>
                            <h2>Nous l'avons fait avec nos propres produits</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/' className='btn btn-outline btn-arrow ms-lg-auto'>
                                Voir notre portfolio
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} src="consulting" />
                    </div>
                </div>
            </div>
        </>
    )
}*/

const Contact = () => {

    const contact_data = {
            title: "Vous voulez lancer votre produit ?",
            title_highlight: "Contactez-nous",
            text: "Le product studio augmenté",
            link: 'https://calendly.com/cheikhbilal/megazorlabs'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
        </>
    )
}


export default Consulting