import Helmet from 'react-helmet'
import { useParams } from "react-router-dom";
import ContactSection from '../../components/contactSection'
import Footer from '../../components/footer'
import NavBar from "../../components/navbar"
import projects from "./projects.json"
import './style.scss'
import { useEffect, useState } from 'react';

const ProjectDetails = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        (() => {
            try {
                const id = parseInt(projectId);
                if(id < projects.length) setProject(projects[id]);
            } catch(e) {
                console.log(e);
            }
        })()
    }, [project, projectId])

    if(project === null) { return null; };
    const { name, title } = project;
    return(
        <>
            <Helmet>
                <title>Megazor Labs - {name}</title>
            </Helmet>
            <NavBar/>
            <div className="project-detail-container">
                <Hero title={title}/>
                <CaseStudy {...project}/>
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = (props) => {
    const { title } = props;
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Étude de cas</div>
                            <h1 className='display-1'>{title}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const CaseStudy = (props) => {
    const {name, what, challenges, solutions, mainPicture, picture} = props
    return(
        <>
            <section className='section-global case-study'>
                <div className="container container-2">
                    <div className="row">
                        <div className="col-12 text-center mb-20">
                            <img src={require(`./assets/${mainPicture}`)} className="img-fluid mb-20" alt="CaseStudy" />
                        </div>
                        <div className="col-12">
                            <div className="case-study-section">                            
                                <h2 className='display-2'>1. Qu'est-ce que {name}?</h2>
                                <p className='txt-1'>{what}</p>
                                <h2 className="display-2">2. Quelles étaient les principaux challenges ?</h2>
                                <p className="txt-1">{challenges}</p>
                                <h2 className="display-2">3. Quelles ont été nos solutions</h2>
                                <p className="txt-1">{solutions}</p>
                                <br/>
                                <img src={require(`./assets/${picture}`)} alt="case-study" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const Contact = () => {

    const contact_data = {
        title: 'Vous avez un projet ?',
        title_highlight: "Contactez-nous",
        text: "Le meilleur product studio du marché.",
        link: 'https://calendly.com/cheikhbilal/megazorlabs'
    }

return(
    <>
        <ContactSection contact_data={contact_data}/>
    </>
)
}


export default ProjectDetails
